<p-dialog
  [(visible)]="visible"
  appendTo="body"
  styleClass="overflow-hidden"
  [modal]="true"
  [dismissableMask]="true"
  [draggable]="false"
  [resizable]="false"
  [focusTrap]="true"
  contentStyleClass="p-0"
  [style]="{ width: '120rem', 'max-width': '95dvw', 'max-height': '90dvh' }"
  (onHide)="onDestroy()"
>
  <p-header>
    <div class="flex gap-2 align-items-center justify-content-center">
      <div
        class="flex align-items-center justify-content-center surface-100 text-cyan-800 border-circle"
        style="width: 2.2rem; height: 2.2rem"
      >
        <i class="fas fa-address-book text-lg"></i>
      </div>
      <div class="font-medium text-xl text-900">
        {{ manageAdressesTranslationKey | transloco }}
      </div>
    </div>
  </p-header>
  <div class="border-top-1 border-300 p-3">
    <div class="flex gap-2 align-content-center">
      @if (!!queryParams.sort && queryParams.sort.length > 0) {
        <p-button
          pRipple
          type="button"
          icon="pi pi-sort-alt-slash"
          [rounded]="true"
          [outlined]="true"
          severity="secondary"
          [style]="{
            width: '2rem',
            height: '2rem',
            'margin-top': '0.4rem'
          }"
          styleClass="border-500"
          (onClick)="resetSort()"
        />
      }
      @if (queryFields.length > 0) {
        <section
          class="flex-1 border-1 border-bluegray-200 border-round-lg mb-1"
        >
          <div
            class="flex w-full align-items-center justify-content-between px-1"
          >
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search text-500"></i>
              <input
                type="text"
                pInputText
                [placeholder]="searchAddressTranslationKey | transloco"
                [(ngModel)]="queryString"
                (ngModelChange)="search($event)"
                class="w-full border-none shadow-none outline-none text-500"
              />
            </span>
            <span
              tabindex="0"
              role="button"
              (click)="queryString = ''; search('')"
              class="py-1 px-2 text-600 cursor-pointer"
              ><i class="fa-regular fa-xmark"></i
            ></span>
          </div>
        </section>
      }
      <p-button
        icon="fas fa-plus"
        [outlined]="true"
        [label]="addAddressTranslationKey | transloco"
        (onClick)="mapa.searchAddress()"
      ></p-button>
    </div>
    <goplanner-filter-bar
      table="ent_direcciones"
      [tableModel]="tableModel"
      containerClass="my-2"
      filterId="address-dialog-filter"
      [filterConfig]="filterConfig"
      [excludeFilters]="excludeFilters"
      [showNumberFilters]="false"
      (filter)="filter($event)"
    />
    @if (selectedAddress) {
      <div
        class="flex flex-row bg-blue-50 my-2 gap-2 overflow-hidden border-round flex-wrap justify-content-end align-items-center"
      >
        <span class="text-lg pl-2 font-medium text-900"
          >{{ selectedAddressTranslationKey | transloco }}:</span
        >
        <div class="flex-1 overflow-x-hidden">
          <goplanner-grid
            class="grid-selected"
            table="ent_direcciones"
            gridActiveField="activo"
            [columns]="columns"
            [tableModel]="tableModel"
            [data]="[selectedAddress]"
            [actions]="actions"
            [showPaginator]="false"
            [columnsConfig]="columnsConfig"
            responsiveLayout="scroll"
            [showHeader]="false"
            size="sm"
            [rows]="1"
            [lazy]="false"
          />
        </div>
      </div>
    }
    <div
      class="flex flex-column md:flex-row gap-3"
      [style]="{ height: '32.25rem' }"
    >
      <div class="md:w-7 h-full">
        <div class="border-round-lg shadow-2 overflow-hidden h-full">
          <goplanner-grid
            #grid
            class="h-full"
            [columns]="columns"
            [data]="(data$ | async) || []"
            [total]="(total$ | async) || 0"
            [loading]="(loading$ | async) || false"
            [actions]="actions"
            [tableModel]="tableModel"
            [columnsConfig]="columnsConfig"
            gridActiveField="activo"
            size="sm"
            [rows]="10"
            [lazy]="true"
            [pageLinks]="3"
            [showPaginator]="true"
            [rowsPerPageOptions]="undefined"
            selectionMode="single"
            [(selection)]="selectedAddress"
            (selectionChange)="onSelectAddress($event)"
            [currentPageReportTemplate]="'{first} - {last} | {totalRecords}'"
            (dblClick)="onDblClick($event)"
            (lazyLoad)="lazyLoad($event)"
          />
        </div>
      </div>
      <div class="md:w-5">
        <satinel-system-map
          #mapa
          height="100%"
          [allowSaveAddress]="true"
          styleClass="h-full border-round overflow-hidden"
          [showRadioTool]="false"
          [showRouteTools]="false"
          [showLocationTool]="false"
          [pointActions]="pointActions"
          (mapCreated)="initMap(); refreshView()"
          (savedAddress)="openSaveAddress(null, $event)"
          accessToken="pk.eyJ1IjoiZnJhbmNpc2NvMTA3IiwiYSI6ImNsZGthdzUxMjAwNGczcW1yYmY3eGwweGwifQ.2MVScz-mVBayubsRAOCfPw"
        />
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    @if (selectable) {
      <div class="border-top-1 surface-border pt-3">
        <p-button
          pRipple
          icon="pi pi-times"
          (onClick)="visible = false"
          [label]="cancelButtonTranslationKey | transloco"
          severity="secondary"
          [text]="true"
        />
        <p-button
          pRipple
          [disabled]="!selectedAddress"
          [label]="selectToAssociateTranslationKey | transloco"
          [icon]="saveButtonIcon"
          [severity]="saveButtonSeverity"
          (onClick)="emitAddress()"
        />
      </div>
    }
  </ng-template>
</p-dialog>
