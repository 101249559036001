import {
  Input,
  Output,
  Component,
  ViewChild,
  ComponentRef,
  EventEmitter
} from '@angular/core'
import { ReplaySubject, take } from 'rxjs'
import { FormsModule } from '@angular/forms'
import { TranslocoModule, TranslocoService } from '@jsverse/transloco'

import { PanelModule } from 'primeng/panel'
import { MessageService } from 'primeng/api'
import { DialogModule } from 'primeng/dialog'
import { ButtonModule } from 'primeng/button'
import { SkeletonModule } from 'primeng/skeleton'
import { InputNumberModule } from 'primeng/inputnumber'

import { AddressPtv } from '@satinel-system/map'

import {
  FieldConfig,
  ErrorService,
  BuilderFields,
  ErrorResponse,
  BackendService,
  EntityInterfaces
} from '@goplanner/api-client'
import {
  goplannerFormModule,
  FormPrepareService,
  FormComponent
} from '@goplanner/form-builder'

import { DialogAddressDetailsComponent } from '../dialog-address-details/dialog-address-details.component'
import { fieldConfigDirecciones } from 'src/app/pages/home/modules/addresses/addresses-fields'

const primeng = [
  PanelModule,
  DialogModule,
  ButtonModule,
  SkeletonModule,
  InputNumberModule
]
@Component({
  selector: 'app-dialog-address-save',
  templateUrl: './dialog-address-save.component.html',
  imports: [FormsModule, TranslocoModule, goplannerFormModule, ...primeng],
  standalone: true
})
export class DialogSaveAddressComponent {
  @ViewChild('form') form!: FormComponent<'ent_direcciones'>

  visible = false

  recordAddress!: EntityInterfaces['ent_direcciones']['get']

  addressPtv!: AddressPtv

  coordenadasString!: string

  formCreated: boolean = false

  formType: 'create' | 'update' = 'create'

  @Input() tableModel: BuilderFields[] = []

  // TRANSLATIONS KEYS
  @Input()
  addAddressTranslationKey: string = 'goplanner.ADDRESS_SEARCH.ADD_ADDRESS'

  @Input()
  editAddressTranslationKey: string = 'goplanner.ADDRESS_SEARCH.EDIT_ADDRESS'

  @Input()
  cancelButtonTranslationKey: string = 'goplanner.COMMON.CANCEL'

  @Input()
  saveButtonTranslationKey: string = 'goplanner.COMMON.SAVE'

  @Input()
  saveAndCloseButtonTranslationKey: string = 'goplanner.COMMON.SAVE_AND_CLOSE'

  @Input()
  editAddressDetailsTranslationKey: string =
    'goplanner.ADDRESS_SEARCH.EDIT_ADDRESS_DETAILS'

  @Input()
  updateAddressDetailTranslationKey: string =
    'goplanner.ADDRESS_SEARCH.UPDATE_ADDRESS_DETAIL'

  @Input()
  saveAddressDetailTranlastionKey: string =
    'goplanner.ADDRESS_SEARCH.SAVE_ADDRESS_DETAIL'

  @Input()
  doneTranslationKey: string = 'goplanner.COMMON.DONE'

  @Input()
  errorSummaryTranslationKey: string = 'goplanner.COMMON.ERROR_SUMMARY'

  @Input()
  errorDetailTranslationKey: string = 'goplanner.COMMON.ERROR'

  @Output()
  destroy = new EventEmitter<void>()

  @Output()
  saved = new EventEmitter<EntityInterfaces['ent_direcciones']['get']>()

  formDetailsAddress!:
    | ComponentRef<
        DialogAddressDetailsComponent<'ent_direcciones', keyof EntityInterfaces>
      >
    | undefined

  editDetails = new EventEmitter<EntityInterfaces['ent_direcciones']['get']>()

  /**
   * Destroy subject
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1)

  includedAddressFields: Array<
    | keyof EntityInterfaces['ent_direcciones']['post']
    | keyof EntityInterfaces['ent_direcciones']['put']
  > = [
    'alias_direccion',
    'nombre',
    'numero',
    'id_poblacion',
    'id_provincia',
    'id_comunidad',
    'id_pais',
    'id_codigo_postal',
    'id_cliente',
    'codigo_erp',
    'tiempo_carga_medio',
    'tiempo_descarga_medio',
    'distrito',
    'subdistrito'
  ]

  fieldAddressConfig: FieldConfig<'ent_direcciones'> = fieldConfigDirecciones

  constructor(
    private backendService: BackendService,
    private prepareForm: FormPrepareService,
    private messageService: MessageService,
    private translate: TranslocoService,
    private errorService: ErrorService
  ) {}

  buildForm() {
    this.backendService
      .getOrLoad('builder', {
        filter: [
          {
            field: 'table_name_parent',
            operator: '=',
            value: 'ent_direcciones'
          }
        ]
      })
      .pipe(take(1))
      .subscribe((tableModel) => (this.tableModel = tableModel))
  }

  inicializeForm(): void {
    this.formCreated = false
    this.formType = this.recordAddress?.id ? 'update' : 'create'
    this.buildForm()

    const record: any = this.recordAddress
    const coordenadas: any =
      'coordenadas' in record && record.coordenadas
        ? record.coordenadas
        : 'direccion' in record &&
            record.direccion &&
            'coordenadas' in record.direccion &&
            record.direccion.coordenadas
          ? record.direccion.coordenadas
          : undefined

    if (
      coordenadas &&
      'type' in coordenadas &&
      coordenadas.type === 'Point' &&
      'coordinates' in coordenadas &&
      Array.isArray(coordenadas.coordinates) &&
      coordenadas.coordinates.length === 2
    )
      this.coordenadasString = `${coordenadas.coordinates[1]},${coordenadas.coordinates[0]}`
  }

  editAddressDetails(
    address: EntityInterfaces['ent_direcciones']['get']
  ): void {
    this.editDetails.emit(address)
  }

  saveAddress(saveAndClose: boolean = false) {
    this.form.form.markAllAsTouched()

    if (!this.form.form.valid) return
    const value = this.form.form.value
    if (this.formType === 'update') {
      this.updateAddress(
        value as EntityInterfaces['ent_direcciones']['put'],
        saveAndClose
      )
    } else {
      this.createAddress(value as EntityInterfaces['ent_direcciones']['post'])
    }
  }

  getCoordenadas(record: any) {
    const coordenadas: any =
      'coordenadas' in record && record.coordenadas
        ? record.coordenadas
        : 'direccion' in record &&
            record.direccion &&
            'coordenadas' in record.direccion &&
            record.direccion.coordenadas
          ? record.direccion.coordenadas
          : undefined
    return { coordenadas: coordenadas }
  }

  loading = false

  private updateAddress(
    value: EntityInterfaces['ent_direcciones']['put'],
    saveAndClose: boolean
  ) {
    const formValues = this.prepareForm.prepareData(
      'ent_direcciones',
      this.tableModel,
      value
    )

    const datosEnvio = {
      id: this.recordAddress.id,
      ...this.getCoordenadas(this.recordAddress),
      ...formValues
    } as EntityInterfaces['ent_direcciones']['put']

    this.sendRequest(
      'put',
      this.updateAddressDetailTranslationKey,
      datosEnvio,
      saveAndClose
    )
  }

  private createAddress(value: EntityInterfaces['ent_direcciones']['post']) {
    const formValues = this.prepareForm.prepareData(
      'ent_direcciones',
      this.tableModel,
      value
    )

    const datosEnvio = {
      ...formValues,
      ...this.getCoordenadas(this.recordAddress)
    } as EntityInterfaces['ent_direcciones']['post']

    this.sendRequest('post', this.saveAddressDetailTranlastionKey, datosEnvio)
  }

  private sendRequest(
    method: 'put' | 'post',
    successMessage: string,
    data:
      | EntityInterfaces['ent_direcciones']['put']
      | EntityInterfaces['ent_direcciones']['post'],
    saveAndClose: boolean = false
  ) {
    const request =
      method === 'put'
        ? this.backendService.put(
            'ent_direcciones',
            data as EntityInterfaces['ent_direcciones']['put']
          )
        : this.backendService.post(
            'ent_direcciones',
            data as EntityInterfaces['ent_direcciones']['post']
          )

    this.loading = true
    ;(request as any).subscribe({
      next: (res: EntityInterfaces['ent_direcciones']['get']) => {
        this.messageService.clear()
        this.messageService.add({
          severity: 'success',
          summary: this.translate.translate(this.doneTranslationKey),
          detail: this.translate.translate(successMessage),
          life: 3000
        })
        if (saveAndClose) {
          this.saved.emit(res)
        } else {
          this.visible = false
          this.saved.emit(res)
        }
        this.loading = false
      },
      error: (error: ErrorResponse<'ent_direcciones'>) => {
        this.errorService.showErrorDialog(
          'ent_direcciones',
          this.tableModel,
          error,
          method === 'put' ? 'update' : 'create'
        )
        this.loading = false
      }
    })
  }

  openGoogleMaps(latlng?: string) {
    if (latlng) {
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latlng}`
      window.open(googleMapsUrl, '_blank')
    }
  }

  /**
   * On destroy hide the dialog and emit the destroy event
   */
  onDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
    this.destroy.emit()
  }
}
