import {
  Input,
  OnInit,
  Output,
  ViewChild,
  Component,
  ComponentRef,
  EventEmitter,
  ViewContainerRef
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import {
  ReplaySubject,
  debounceTime,
  Observable,
  takeUntil,
  mergeMap,
  Subject,
  take,
  map,
  of
} from 'rxjs'
import _ from 'lodash'
import { TranslocoModule } from '@jsverse/transloco'

import { LngLat, Marker } from 'mapbox-gl'

import { PanelModule } from 'primeng/panel'
import { DialogModule } from 'primeng/dialog'
import { ButtonModule } from 'primeng/button'
import { SkeletonModule } from 'primeng/skeleton'
import { TableLazyLoadEvent } from 'primeng/table'
import { InputTextModule } from 'primeng/inputtext'

import {
  SatinelMapModule,
  MapComponent,
  PointAction,
  AddressPtv
} from '@satinel-system/map'
import { IAction } from '@satinel-system/grid'

import { FilterConfig, goplannerFilterBarModule } from '@goplanner/form-builder'
import { goplannerGridModule, GridComponent } from '@goplanner/grid-builder'
import {
  GridAdapterService,
  EntityInterfaces,
  BackendService,
  BuilderFields,
  ColumnConfig,
  QueryParams,
  FilterField,
  FilterParam,
  TableField
} from '@goplanner/api-client'

import { DialogAddressDetailsComponent } from './dialog-address-details/dialog-address-details.component'
import { ConfirmationUpdateComponent } from '../confirmation-update/confirmation-update.component'
import { DialogSaveAddressComponent } from './dialog-address-save/dialog-address-save.component'
import {
  columnConfigDirecciones,
  filterConfigDirecciones
} from 'src/app/pages/home/modules/addresses/addresses-fields'

const primeng = [
  PanelModule,
  DialogModule,
  ButtonModule,
  SkeletonModule,
  InputTextModule
]

@Component({
  selector: 'app-dialog-address',
  templateUrl: './dialog-address.component.html',
  styleUrls: ['./dialog-address.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    TranslocoModule,
    SatinelMapModule,
    goplannerGridModule,
    goplannerFilterBarModule,
    ...primeng
  ],
  standalone: true
})
export class DialogAddressComponent implements OnInit {
  /**
   * Destroy subject
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1)

  visible = false

  @ViewChild('mapa') mapa!: MapComponent

  @ViewChild('grid') grid!: GridComponent<'ent_direcciones'>

  selectedAddress!: EntityInterfaces['ent_direcciones']['get']

  selectable = true

  saveButtonIcon = 'pi pi-link'

  saveButtonSeverity:
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'help'
    | 'primary'
    | 'secondary'
    | 'contrast'
    | null
    | undefined = 'primary'

  // TRANSLATIONS KEYS
  @Input()
  manageAdressesTranslationKey = 'goplanner.ADDRESS_SEARCH.MANAGE_ADDRESSES'

  @Input()
  searchAddressTranslationKey =
    'goplanner.ADDRESS_SEARCH.SEARCH_ADDRESS_PLACEHOLDER'

  @Input()
  addAddressTranslationKey = 'goplanner.ADDRESS_SEARCH.CREATE_ADDRESS'

  @Input()
  selectedAddressTranslationKey = 'goplanner.COMMON.SELECTED_FEMALE'

  @Input()
  cancelButtonTranslationKey = 'goplanner.COMMON.CANCEL'

  @Input()
  selectToAssociateTranslationKey = 'goplanner.COMMON.SELECT_TO_ASSOCIATE'

  @Output() saved = new EventEmitter<
    EntityInterfaces['ent_direcciones']['get']
  >()

  tableModel: BuilderFields[] = []

  direccionesData: EntityInterfaces['ent_direcciones']['get'][] = []

  data$!: Observable<EntityInterfaces['ent_direcciones']['get'][]>

  total$!: Observable<number>

  loading$!: Observable<boolean>

  totalDirecciones: number = 0

  direccionMarker: Marker | undefined

  formSaveAddress!: ComponentRef<DialogSaveAddressComponent> | undefined

  baseFilter: (FilterParam<'ent_direcciones'> | 'AND' | 'OR')[] = [
    {
      field: 'activo',
      operator: '=',
      value: true
    }
  ]

  baseRecord: Partial<EntityInterfaces['ent_direcciones']['get']> = {}

  excludeFilters: FilterField<'ent_direcciones'>[] = ['activo']

  confirmationUpdate!:
    | ComponentRef<ConfirmationUpdateComponent<'ent_direcciones'>>
    | undefined

  formAsociateAddress!:
    | ComponentRef<
        DialogAddressDetailsComponent<'ent_direcciones', keyof EntityInterfaces>
      >
    | undefined

  actions: IAction<EntityInterfaces['ent_direcciones']['get']>[] = [
    {
      icon: 'pi pi-pencil',
      color: 'p-button-success',
      handler: (record: EntityInterfaces['ent_direcciones']['get']) =>
        this.openSaveAddress(record)
    },
    {
      icon: 'fas fa-list',
      color: 'p-button-info',
      handler: (record: EntityInterfaces['ent_direcciones']['get']) =>
        this.editAddressDetails(record)
    },
    {
      icon: 'fas fa-times',
      color: 'p-button-danger',
      tooltip: 'Desactivar dirección',
      handler: (record: EntityInterfaces['ent_direcciones']['get']) => {
        if (this.confirmationUpdate) return
        const confirmMessage = record.activo
          ? '¿Desea desactivar la dirección?'
          : '¿Desea activar la dirección?'

        this.confirmationUpdate = this.viewContainerRef.createComponent(
          ConfirmationUpdateComponent<'ent_direcciones'>
        )

        this.confirmationUpdate.instance.id = record.id
        this.confirmationUpdate.instance.entityName = 'ent_direcciones'
        this.confirmationUpdate.instance.values = {
          activo: !record.activo
        }
        this.confirmationUpdate.instance.confirmMessage = confirmMessage
        this.confirmationUpdate.instance.type = record.activo
          ? 'danger'
          : 'success'

        const afterUpdate =
          this.confirmationUpdate.instance.afterUpdate.subscribe(() => {
            this.backendService
              .get('ent_direcciones', this.queryParams)
              .pipe(take(1))
              .subscribe()
          })

        const destroy = this.confirmationUpdate.instance.destroy
          .pipe(debounceTime(100))
          .subscribe(() => {
            this.confirmationUpdate?.destroy()
            this.confirmationUpdate = undefined
            afterUpdate?.unsubscribe()
            destroy?.unsubscribe()
          })
      }
    }
  ]

  value!: number | number[]

  valueField!: string

  columns: TableField<'ent_direcciones'>[] = [
    'nombre',
    'alias_direccion',
    'direccion_formateada',
    'pais__nombre',
    'comunidad__nombre',
    'provincia__nombre',
    'poblacion__nombre',
    'codigo_postal__codigo_postal',
    'coordenadas',
    'tiempo_carga_medio',
    'tiempo_descarga_medio'
  ]

  columnsConfig: ColumnConfig<'ent_direcciones'> = columnConfigDirecciones

  queryFields: FilterField<'ent_direcciones'>[] = [
    'nombre',
    'alias_direccion',
    'direccion_formateada',
    'pais__nombre',
    'comunidad__nombre',
    'provincia__nombre',
    'poblacion__nombre',
    'codigo_postal__codigo_postal'
  ]

  filterConfig: FilterConfig<'ent_direcciones'> = filterConfigDirecciones

  @Output()
  destroy = new EventEmitter<void>()

  openFormAddress: boolean = false

  constructor(
    private backendService: BackendService,
    private gridAdapter: GridAdapterService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.queryString$
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((query) => this.query(query))

    this.backendService
      .getOrLoad('builder', {
        filter: [
          {
            field: 'table_name_parent',
            operator: '=',
            value: 'ent_direcciones'
          }
        ]
      })
      .pipe(take(1))
      .subscribe((tableModel) => {
        this.tableModel = tableModel
        this.total$ = this.backendService.total$('ent_direcciones')
        this.loading$ = this.backendService.loading$('ent_direcciones')
        this.queryParams = {
          filter: this.baseFilter,
          limit: 10,
          offset: 0
        }
        if (this.value && (!Array.isArray(this.value) || this.value.length > 0))
          this.getSelectedElements().subscribe((res) => {
            this.selectedAddress = res[0]
            if (this.grid) this.grid.selection = this.selectedAddress
            else
              setTimeout(() => (this.grid.selection = this.selectedAddress), 0)
            setTimeout(
              () => this.onChangeSelectedAddress(this.selectedAddress),
              200
            )
            this.data$ = this.backendService.get(
              'ent_direcciones',
              this.queryParams
            )
          })
        else {
          this.data$ = this.backendService.get(
            'ent_direcciones',
            this.queryParams
          )
          this.refreshView()
        }
      })
  }

  /**
   * Get selected elements from the cache and the backend
   * @returns the selected elements observable
   */
  getSelectedElements() {
    return this.backendService
      .getCached('ent_direcciones', {
        filter: [
          {
            field: this.valueField as FilterField<'ent_direcciones'>,
            operator: 'in',
            value: Array.isArray(this.value) ? this.value : [this.value]
          }
        ]
      })
      .pipe(
        take(1),
        mergeMap((items) => {
          if (
            !items ||
            (Array.isArray(this.value)
              ? items.length < this.value.length
              : !items.length)
          )
            return this.backendService
              .getEntityCollection('ent_direcciones')
              .dataService.get({
                filter: [
                  {
                    field: this.valueField as FilterField<'ent_direcciones'>,
                    operator: 'in',
                    value: Array.isArray(this.value)
                      ? this.value.filter(
                          (s) =>
                            !items?.find(
                              (i) =>
                                this.backendService.getFieldValue(
                                  i,
                                  this.valueField
                                ) === s
                            )
                        )
                      : [this.value]
                  }
                ]
              })
              .pipe(
                take(1),
                map((getResponse) => [...items, ...getResponse.data])
              )
          else return of(items)
        })
      )
  }

  initMap() {
    if (!this.mapa.singleMarkerActive) this.mapa.removeSingleMarker()
  }

  refreshView() {
    setTimeout(() => this.mapa.map.resize(), 200)
  }

  pointActions: PointAction[] = [
    {
      icon: 'pi pi-times',
      styleClass: 'p-button-danger p-button-outlined',
      action: () => this.removeMarker()
    }
  ]

  removeMarker() {
    this.mapa.singleMarker?.remove()
    this.mapa.singleMarker = null
  }

  /**
   * add edit address
   */
  openSaveAddress(
    recordAddress?: EntityInterfaces['ent_direcciones']['get'] | null,
    location?: {
      address?: AddressPtv
      coordinates?: LngLat
    }
  ): void {
    this.formSaveAddress = this.viewContainerRef.createComponent(
      DialogSaveAddressComponent
    )

    const form = this.formSaveAddress

    if (recordAddress?.id) {
      form.instance.recordAddress = recordAddress
      form.instance.visible = true
      form.instance.inicializeForm()
    } else if (location?.address) {
      this.backendService
        .call(
          'ent_direcciones' as any,
          'GET:ptv',
          {
            coordenadas: `${location.coordinates?.lat},${location.coordinates?.lng}`
          },
          {}
        )
        .subscribe({
          next: (data) => {
            form.instance.recordAddress = {
              ...data,
              ...this.baseRecord
            }
            ;(form.instance.recordAddress as any).coordenadas =
              location.coordinates
                ? {
                    type: 'Point',
                    coordinates: [
                      location.coordinates.lng,
                      location.coordinates.lat
                    ]
                  }
                : undefined
            form.instance.visible = true
            form.instance.inicializeForm()

            this.mapa.savingAddress = false
          },
          error: (error) => {
            console.error('Error fetching address:', error)
          }
        })
    } else if (location?.coordinates) {
      form.instance.recordAddress =
        {} as EntityInterfaces['ent_direcciones']['get']
      ;(form.instance.recordAddress as any).coordenadas = {
        type: 'Point',
        coordinates: [location.coordinates?.lng, location.coordinates?.lat]
      }
      form.instance.visible = true
      form.instance.inicializeForm()

      this.mapa.savingAddress = false
    }

    const afterSave = this.formSaveAddress.instance.saved
      .pipe(takeUntil(this.destroy$))
      .subscribe((address) => {
        // direccion guardada
        this.backendService
          .getCached('ent_direcciones', this.queryParams)
          .pipe(take(1))
          .subscribe()
        this.removeMarker()
        this.onSelectAddress(address)
      })

    const onEditDetails = this.formSaveAddress.instance.editDetails.subscribe(
      (address: EntityInterfaces['ent_direcciones']['get']) => {
        this.editAddressDetails(address)
      }
    )
    const destroy = this.formSaveAddress.instance.destroy
      .pipe(takeUntil(this.destroy$), debounceTime(200))
      .subscribe(() => {
        onEditDetails?.unsubscribe()
        afterSave?.unsubscribe()
        destroy?.unsubscribe()
        this.formSaveAddress?.destroy()
        this.formSaveAddress = undefined
      })
  }

  onDblClick(event: EntityInterfaces['ent_direcciones']['get']) {
    if (!this.selectable) {
      this.openSaveAddress(event)
    } else {
      this.saved.emit(event)
      this.visible = false
    }
  }

  onSelectAddress(
    address:
      | EntityInterfaces['ent_direcciones']['get']
      | EntityInterfaces['ent_direcciones']['get'][]
  ) {
    if (Array.isArray(address)) return
    if (!this.mapa) return
    // borro this.direccionMarker si existe
    if (this.direccionMarker) {
      this.direccionMarker.remove()
    }

    if (!address) {
      this.mapa.resetView()
      return
    }

    this.selectedAddress = address
    this.onChangeSelectedAddress(this.selectedAddress)
  }

  onChangeSelectedAddress(address: any) {
    const coordenadas: any =
      'coordenadas' in address && address.coordenadas
        ? address.coordenadas
        : 'direccion' in address &&
            address.direccion &&
            typeof address.direccion === 'object' &&
            'coordenadas' in address.direccion &&
            address.direccion.coordenadas
          ? address.direccion.coordenadas
          : undefined

    if (
      coordenadas &&
      'type' in coordenadas &&
      coordenadas.type === 'Point' &&
      'coordinates' in coordenadas &&
      Array.isArray(coordenadas.coordinates) &&
      coordenadas.coordinates.length === 2
    ) {
      this.direccionMarker = this.mapa.createMarker({
        icon: '',
        iconcolor: '#fff',
        backgroundcolor: 'red',
        lnglat: [coordenadas.coordinates[0], coordenadas.coordinates[1]]
      })

      this.mapa.map.flyTo({
        center: [coordenadas.coordinates[0], coordenadas.coordinates[1]],
        zoom: 15,
        duration: 200
      })
    }
  }

  editAddressDetails(
    address: EntityInterfaces['ent_direcciones']['get']
  ): void {
    this.formAsociateAddress = this.viewContainerRef.createComponent(
      DialogAddressDetailsComponent<'ent_direcciones', keyof EntityInterfaces>
    )
    this.formAsociateAddress.instance.entityName = 'ent_direcciones'
    if (address) {
      this.formAsociateAddress.instance.recordAddress = address
    }

    this.formAsociateAddress.instance.visible = true
    const afterSave = this.formAsociateAddress.instance.saved
      .pipe(takeUntil(this.destroy$), debounceTime(200))
      .subscribe((address) => this.onSelectAddress(address))

    const destroy = this.formAsociateAddress.instance.destroy
      .pipe(takeUntil(this.destroy$), debounceTime(200))
      .subscribe(() => {
        afterSave?.unsubscribe()
        destroy?.unsubscribe()
        this.formAsociateAddress?.destroy()
        this.formAsociateAddress = undefined
      })
  }

  /**
   * The query params
   */
  queryParams: QueryParams<'ent_direcciones'> = {}

  /**
   * The query string
   */
  queryString = ''

  /**
   * The form filters
   */
  formFilters: FilterParam<'ent_direcciones'>[] = []

  /**
   * The query filters
   */
  queryFilters: (FilterParam<'ent_direcciones'> | 'OR' | 'AND')[] = []

  /**
   * The query string subject
   */
  private queryString$ = new Subject<string>()

  /**
   * On lazy load, change the query
   * @param event the lazy load event
   */
  lazyLoad(event: TableLazyLoadEvent) {
    if (!this.tableModel.length) return
    this.changeQuery({
      ...this.gridAdapter.parseGridLazyLoadEvent('ent_direcciones', event),
      filter: [
        ...(this.formFilters ?? []),
        ...(this.queryFilters ?? []),
        ...this.baseFilter
      ]
    })
  }

  /**
   * On search, emit to the query string subject
   * @param query the query string
   */
  search(query: string) {
    this.queryString$.next(query)
  }

  /**
   * On filter, change the query and reset the page
   * @param model the filter model
   */
  filter(event: {
    model: {
      [key: string]: any
    }
    filterTypes: {
      [key: string]: 'not in' | 'in' | 'is null' | 'is not null'
    }
  }) {
    this.formFilters = this.gridAdapter.parseFilterForm(
      'ent_direcciones',
      this.tableModel,
      event.model,
      event.filterTypes
    )
    if (this.queryParams.offset) {
      delete this.queryParams.offset
      this.grid.grid.dataTable.first = 0
    }

    this.changeQuery({
      ...this.queryParams,
      filter: [
        ...(this.formFilters ?? []),
        ...(this.queryFilters ?? []),
        ...this.baseFilter
      ]
    })
  }

  /**
   * On query, change the query and reset the page
   * @param query the query string
   */
  query(query: string): void {
    this.queryString = query
    this.queryFilters =
      this.queryString && this.queryString !== '' && this.queryFields.length > 0
        ? this.gridAdapter.parseQueryFilters(
            'ent_direcciones',
            this.queryString,
            this.queryFields
          )
        : []

    if (this.queryParams.offset) {
      delete this.queryParams.offset
      this.grid.grid.dataTable.first = 0
    }

    this.changeQuery({
      ...this.queryParams,
      filter: [
        ...(this.formFilters ?? []),
        ...(this.queryFilters ?? []),
        ...this.baseFilter
      ]
    })
  }

  /**
   * On reset, reset the sort and page
   */
  resetSort() {
    this.grid.resetSort()
    if (this.queryParams.offset) delete this.queryParams.offset
    this.changeQuery({
      ...this.queryParams,
      sort: []
    })
  }

  /**
   * Function to change the query
   * @param query the query params
   */
  changeQuery(query: QueryParams<'ent_direcciones'>) {
    // If the query is the same, do nothing
    if (_.isEqual(query, this.queryParams)) return
    // If the filter or sort changed, clear the cache
    if (
      !_.isEqual(query.filter, this.queryParams.filter) ||
      !_.isEqual(query.sort, this.queryParams.sort)
    ) {
      this.backendService.getEntityCollection('ent_direcciones').clearCache()
    }
    this.queryParams = {
      ...query,
      limit: 10
    }
    this.backendService
      .getOrLoad('ent_direcciones', this.queryParams)
      .pipe(take(1))
      .subscribe()
  }

  emitAddress() {
    this.saved.emit(this.selectedAddress)
    this.visible = false
  }

  /**
   * On destroy hide the dialog and emit the destroy event
   */
  onDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
    this.destroy.emit()
  }
}
